.ant-picker {
  width: 100%;
  border: 1px solid #dfe1e4;
  box-shadow: none;
  &:hover {
    border: 1px solid #dfe1e4;
    box-shadow: none;
  }
}
.ant-picker-focused {
  border: 1px solid #3562d4;
  box-shadow: none;
  &:hover {
    border: 1px solid #3562d4;
    box-shadow: none;
  }
}